AOS.init();

// OffCanvas
$(".off-canvas-toggle").click(function () {
    $(".hamburger").toggleClass("is-active");
    $(".nav-wrapper").toggleClass("active");
    $("body").toggleClass("no-scroll");
});

$(".nav-wrapper > ul > li > a").click(function () {
    $(".nav-wrapper").removeClass("active");
    $(".hamburger").removeClass("is-active");
    $("body").removeClass("no-scroll");
});

// Press "ESC"

$(document).keyup(function (e) {
    if (e.keyCode == 27) {
        $(".hamburger").removeClass("is-active");
        $(".nav-wrapper").removeClass("active");
        $("body").removeClass("no-scroll");
    }
});

$("a[href^='#']").click(function(e) {
    
    var position = $($(this).attr("href")).offset().top;
    
    if ($(window).width() > 900) {
        $("body, html").animate({
            scrollTop: position - 140
            }, 750,
            'linear' 
        ); 
     }
     else {
        $("body, html").animate({
            scrollTop: position - 100
            }, 750,
            'linear' 
        ); 
     }
});